import React from "react"
import { BuilderComponent, Builder  } from '@builder.io/react';
import ModelPreview from "../components/Builder/ModelPreview"
import {Home, modelFieldsConfig}  from "../layouts/page"

const Dev404 =() => {
  const [notFound, setNotFound] = React.useState(false);

  return notFound ? (
    <NotFound /> // Your 404 content
  ) : (
    <ModelPreview
      modelFieldsConfig={modelFieldsConfig} 
      model="page" contentLoaded={(pageContext) => {
        return <BuilderComponent inlineContent={true} model="page" content={pageContext.data}/>
      }} />
  );
};

const NotFound = () => <h1>No page found for this URL, did you publish it?</h1>;

export default Dev404
